
import { ref, computed, onMounted, defineComponent } from 'vue';

import IconArrow from './IconArrow.vue';
import ViewPaginationMiddle from './ViewPaginationMiddle.vue';
import ViewPaginationEnd from './ViewPaginationEnd.vue';
import ViewPaginationStart from './ViewPaginationStart.vue';

import useTailwind from '@/use/useTailwind';

const PAGE_CHANGE_EVENT = 'pageChange';

export default defineComponent({
  components: {
    IconArrow,
    ViewPaginationMiddle,
    ViewPaginationEnd,
    ViewPaginationStart,
  },
  props: {
    totalItems: {
      type: Number,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 21,
    },
    idRef: {
      type: String,
      required: true,
    },
    theme: {
      type: String,
      default: 'dark'
    }
  },
  emits: [PAGE_CHANGE_EVENT],
  setup(props, { emit }) {
    const tailwindTheme = useTailwind().theme;

    const currentPage = ref(1);
    const pagesShowEnd = ref(4);
    const pagesShowStart = ref(4);

    const minimumTotalPagesToApplyTrimView = computed(() => {
      return pagesShowEnd.value >= pagesShowStart.value
        ? pagesShowEnd.value + 1
        : pagesShowStart.value + 1;
    });

    const totalPages = computed(() =>
      Math.ceil(props.totalItems / props.itemsPerPage)
    );

    /**
     * @name pageChange
     * @desc We set currentPage selected and emit the range of indexes the parent should take from items array
     * --> [4-9] Parent should slice the item array from 4 to 9
     * @param {Number} page - the page selected
     */
    const pageChange = (page) => {
      const pageIndex = page - 1;
      const startChunk = pageIndex * props.itemsPerPage;
      const endChunk = startChunk + props.itemsPerPage;

      currentPage.value = page;

      emit(PAGE_CHANGE_EVENT, startChunk, endChunk);
    };

    const hasPreviousPage = computed(() => currentPage.value > 1);
    const hasNextPage = computed(() => currentPage.value < totalPages.value);

    /**
     * @name componentToShow
     * @description This computed choose which view paginator should display
     * The start view --> first pages, dots, last page
     * The middle view --> first page, dots, 3 middle pages, dots, last page
     * The end view --> first page, dots, the last pages
     */
    const componentToShow = computed(() => {
      const lastValue = totalPages.value - pagesShowEnd.value + 1;

      const displayMiddle =
        pagesShowStart.value <= currentPage.value &&
        currentPage.value <= lastValue;
      const displayEnd = lastValue < currentPage.value;

      return displayMiddle
        ? 'ViewPaginationMiddle'
        : displayEnd
        ? 'ViewPaginationEnd'
        : 'ViewPaginationStart';
    });

    onMounted(() => {
      const skipScrollProductsIntoView = true;
      // emit the first range of items to show
      emit(PAGE_CHANGE_EVENT, 0, props.itemsPerPage, skipScrollProductsIntoView);
    });

    return {
      tailwindTheme,
      pageChange,
      currentPage,
      hasPreviousPage,
      hasNextPage,
      totalPages,
      componentToShow,
      minimumTotalPagesToApplyTrimView,
      pagesShowStart,
      pagesShowEnd,
    };
  },
});
