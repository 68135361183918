import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "pagination" }
const _hoisted_2 = ["id", "disabled"]
const _hoisted_3 = ["id", "onClick"]
const _hoisted_4 = ["id", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconArrow = _resolveComponent("IconArrow")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      id: `${_ctx.idRef}_previous_page`,
      disabled: !_ctx.hasPreviousPage,
      class: _normalizeClass([{ 'cursor-default': !_ctx.hasPreviousPage }, "pr-l"]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.currentPage !== 1 && _ctx.pageChange(_ctx.currentPage - 1)))
    }, [
      _createVNode(_component_IconArrow, {
        disabled: !_ctx.hasPreviousPage,
        class: "pointer-events-none",
        stroke: _ctx.theme === 'light' ? _ctx.tailwindTheme.colors['primary-soft-white'] : null
      }, null, 8, ["disabled", "stroke"])
    ], 10, _hoisted_2),
    (_ctx.totalPages > _ctx.minimumTotalPagesToApplyTrimView)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentToShow), {
          key: 0,
          currentPage: _ctx.currentPage,
          totalPages: _ctx.totalPages,
          pagesShowStart: _ctx.pagesShowStart,
          onInnerPageChange: _ctx.pageChange
        }, null, 40, ["currentPage", "totalPages", "pagesShowStart", "onInnerPageChange"]))
      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.totalPages, (page) => {
          return (_openBlock(), _createElementBlock("button", {
            id: `${_ctx.idRef}_page_change_${page}`,
            key: page,
            class: _normalizeClass(["pagination__page", {
          'pagination__page--active': _ctx.currentPage === page,
          'text-primary-soft-white': _ctx.theme === 'light',
        }]),
            onClick: ($event: any) => (_ctx.pageChange(page))
          }, _toDisplayString(page), 11, _hoisted_3))
        }), 128)),
    _createElementVNode("button", {
      id: `${_ctx.idRef}_next_page`,
      disabled: !_ctx.hasNextPage,
      class: _normalizeClass([{ 'cursor-default': !_ctx.hasNextPage }, "pl-l"]),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.currentPage !== _ctx.totalPages && _ctx.pageChange(_ctx.currentPage + 1)))
    }, [
      _createVNode(_component_IconArrow, {
        disabled: !_ctx.hasNextPage,
        class: "transform rotate-180 pointer-events-none",
        stroke: _ctx.theme === 'light' ? _ctx.tailwindTheme.colors['primary-soft-white'] : null
      }, null, 8, ["disabled", "stroke"])
    ], 10, _hoisted_4)
  ]))
}