 <template>
    <button
      v-for="page in pagesShowStart"
      :key="page"
      class="pagination-start__page"
      :class="{'pagination-start__page--active': currentPage === page}"
      @click="$emit('innerPageChange', page)"
    >
      {{page}}
    </button>
    <div class="pagination-start__page flex items-center justify-center">...</div>
    <button
      class="pagination-start__page"
      @click="$emit('innerPageChange', totalPages)"
    >
      {{totalPages}}
    </button>
</template>

<script>
  export default {
    props: {
      currentPage: {
        type: Number,
        required: true
      },
      totalPages: {
        type: Number,
        required: true,
      },
      pagesShowStart: {
        type: Number,
        default: 4
      }
    },
    emits: ['innerPageChange'],
  }
</script>
<style lang="scss">
  .pagination-start {
    &__page {
      @apply w-10 h-10
             rounded-full
             relative;

      &--active {
        @apply bg-primary-turquoise
      }
    }
  }
</style>