
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    stroke: {
      type: String,
      default: '#34303D'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const strokeColor = computed(() => props.disabled ? '#99979e' : props.stroke);
    return {
      strokeColor
    }
  }
})
