
import { defineComponent } from 'vue';
import { HelpTopics } from './ItemStack.types';
import Icon, { ICON_THEMES } from '@/components/Icon/Icon.vue';

export default defineComponent({
  components: {
    Icon,
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    infoLink: {
      type: String,
      default: ''
    },
    items: {
      type: Array as () => HelpTopics[],
      default: () => [],
    },
  },
  emits: ['itemClick'],
  setup(_, { emit }) {
    const onItemClick = () => {
      emit('itemClick');
    };
    return {
      ICON_THEMES,
      onItemClick,
    };
  },
});
