import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "r-accordion" }
const _hoisted_2 = ["id"]
const _hoisted_3 = { class: "px-xl pb-xl md:pb-xxl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      id: _ctx.idRef,
      class: "flex flex-row justify-between px-xl pt-xl pb-m md:pb-xl w-full text-left",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
    }, [
      _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
      _createVNode(_component_Icon, {
        type: _ctx.isOpen ? 'minus' : 'plus',
        class: "transform mx-1 w-6 h-auto pointer-events-none"
      }, null, 8, ["type"])
    ], 8, _hoisted_2),
    _withDirectives(_createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default")
    ], 512), [
      [_vShow, _ctx.isOpen]
    ])
  ]))
}