
import { defineComponent } from 'vue';

import Icon from '@/components/Icon/Icon.vue';

export default defineComponent({
  components: { Icon },
  props: {
    title: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      default: false,
    },
    idRef: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isOpen: this.open,
    };
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
  },
});
