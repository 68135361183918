
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    pagesShowEnd: {
      type: Number,
      default: 4,
    },
  },
  emits: ['innerPageChange'],
  setup(props) {
    /**
     * @name pageOnEnd
     * @description In the template we loop through how many pages the paginator should have at the end
     * To apply the right page number we take totalPages substract how many pages has in the end and we add the loop number
     * --> (30 - 4) + 1 = 27
     * --> (30 - 4) + 2 = 28
     * --> (30 - 4) + 3 = 29
     * --> (30 - 4) + 4 = 30
     * Those are the last 4 pages
     * @param {Number} loopNumber Loop number start from 1
     */
    const pageOnEnd = (loopNumber) =>
      props.totalPages - props.pagesShowEnd + loopNumber;

    return {
      pageOnEnd,
    };
  },
});
