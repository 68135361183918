<template>
  <button
  class="pagination-middle__page"
  @click="$emit('innerPageChange', 1)"
  >1</button>
  <div class="pagination-middle__page flex items-center justify-center">...</div>
  <button
    class="pagination-middle__page"
    @click="$emit('innerPageChange', currentPage - 1)"
  >
    {{currentPage - 1}}
  </button>
  <button
    class="pagination-middle__page pagination-middle__page--active"
    @click="$emit('innerPageChange', currentPage)"
  >
    {{currentPage}}
  </button>
  <button
    class="pagination-middle__page"
    @click="$emit('innerPageChange', currentPage + 1)"
  >
    {{currentPage + 1}}
  </button>
  <div class="pagination-middle__page flex items-center justify-center">...</div>
  <button
    class="pagination-middle__page"
    @click="$emit('innerPageChange', totalPages)"
  >
    {{totalPages}}
  </button>
</template>

<script>
  export default {
    props: {
      currentPage: {
        type: Number,
        required: true
      },
      totalPages: {
        type: Number,
        required: true,
      }
    },
    emits: ['innerPageChange'],
  }
</script>
<style lang="scss">
  .pagination-middle {
    &__page {
      @apply w-10 h-10
             rounded-full
             relative;

      &--active {
        @apply bg-primary-turquoise
      }
    }
  }
</style>